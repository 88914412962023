import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
// import { useAuth } from '../authProvider/AuthProvider';

const AdContext=createContext();

// Custom hook to access the ad data and fetch function


const AdsProvider = ({ children }) => {
    // const { domainName}= useAuth();
   
    const [adData, setAdData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const response= await axios.get(`/api/guest/getads`);
            setAdData(response.data.ads);

        };
        fetchData();
    }, []);
  return (
   <AdContext.Provider value={adData}>
    { children}
   </AdContext.Provider>
  )
}

export default AdsProvider

export const useAd = () => useContext(AdContext);
