import React from "react";
import Lottie from 'react-lottie';
import LottieFile from './Animation - 1710645341337.json'
import  './loading.css'

const Loading=()=>{
    const defaultOPtions={
        loop:true,
        autoplay:true,
        animationData:LottieFile,
        rendererSettings:{
            preserveAspectRAtio:'xMidYMid slice'
        }


    }

    return (
        <>
        <div className="main_containerLoading">
            <div className="Loading_box">
            <Lottie
             options={defaultOPtions}
             height={110}
             width={120}
             isClickToPauseDisabled={true}
             className="search_lottie"
            />
            <p>LOADING</p>
            </div>
           
        </div>
        </>
    )
}

export default Loading