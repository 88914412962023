import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Loading from './components/Loading/Loading.jsx'
import AdsProvider from './components/ads/AdsProvider.jsx';
import AuthProvider from './components/authProvider/AuthProvider.jsx';
const Home = lazy(() => import('./pages/homePage/Home.jsx'));
const AboutUs=lazy(()=>import("./components/AboutUs/AboutUs.jsx"))
const Navbar = lazy(() => import('./components/Navbar/Navbar.jsx'));
const CategoryPage = lazy(() => import('./pages/categoryPage/CategoryPage.jsx'));
const LoginSignup = lazy(() => import('./pages/Loginsignup/LoginSignup.jsx'));
const ClientPage = lazy(() => import('./pages/ClientPage/ClientPage.jsx'));
const UserDashboard = lazy(() => import('./pages/UserDashboard/UserDashboard.jsx'));
const AdminDashboard = lazy(() => import('./pages/adminDashboard/AdminDashboard.jsx'));
const Signup=lazy(()=>import ('./components/signup/Signup.jsx'))
const ContactUs=lazy(()=>import ('./components/ContactUs/ContactUs.jsx'))
const PrivateRoute=lazy(()=>import ('./components/PrivateRoute/PrivateRoute.jsx'))
const Ads=lazy(()=>import ('./pages/UploadAds/UploadAds.jsx'))
const UnAuthorized=lazy(()=>import ('./pages/otherPages/Unauthorized.jsx'))
const NotFound=lazy(()=>import ('./pages/otherPages/PageNotFound.jsx'))
const ForgotPassword=lazy(()=>import ('./components/login/ResetPassword.jsx'))
const Help=lazy(()=>import ('./components/faq/Help.jsx'))
const AddBusiness=lazy(()=>import ('./components/faq/Addbusiness.jsx'))



const App = () => {
  return (
    <Router>
      <Suspense fallback={<Loading/>}>
        <AuthProvider>
      <AdsProvider>
        <Navbar />
        <Routes>
         
          <Route exact path="/" element={<Home />} />
         
          <Route exact path="/category/:categoryName" element={<CategoryPage />} />
          <Route exact path="/login" element={<LoginSignup />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route exact path="/aboutus" element={<AboutUs/>} />
          <Route exact path="/unauthorized" element={<UnAuthorized/>} />
          <Route exact path="/ads" element={<Ads/>} />
          <Route exact path="/category/:categoryName/venueid/:venueID" element={<ClientPage />} />
          <Route element={< PrivateRoute  allowedRoles={['admin']} />}>
             <Route path="/admindashboard" element={<AdminDashboard />} />
             </Route>
          <Route element={< PrivateRoute allowedRoles={['user', 'admin']} />}>
               <Route path="/userdashboard" element={<UserDashboard />} />
           </Route> 
           <Route exact path="/forgotpassword" element={<ForgotPassword/>} />
           <Route exact path="/help" element={<Help/>} />
           <Route exact path="/addbusiness" element={<AddBusiness/>} />
           <Route exact path="*" element={<NotFound/>} />
     

        </Routes>
        </AdsProvider>
        </AuthProvider>
      </Suspense>
    </Router>
  );
};

export default App;
